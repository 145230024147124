<template>
  <div>
    <v-container class="" v-if="listArea.length == 0 && listProvince.length == 0 && listSoil.length == 0" full-height>
      <div class="flex al-c jc-c w-100 h-100">
        <v-progress-circular
        :indeterminate="true"
        color="primary"
        ></v-progress-circular>
      </div>
    </v-container>
    <v-container grid-list-md v-else>
      <p class="header border-radius-content">
        &nbsp;&nbsp;&nbsp;การปลูกไม้เศรษฐกิจให้สามารถเติบโตได้ดี จะต้องคำนึงถึงชนิดไม้ที่เหมาะสมกับพื้นที่และปัจจัยที่สำคัญ โดยเฉพาะการลงทุนที่ควรลดความเสี่ยงการลงทุนให้น้อยที่สุด
        <br>ท่านสามารถตรวจสอบชนิดไม้ที่เหมาะสมต่อการปลูกกับพื้นที่ของท่านได้โดยเลือกจังหวัด พื้นที่ และชนิดดิน ได้จากแถบตัวเลือกด้านซ้ายมือ
      </p>
      <v-layout row wrap>
        <v-flex xs12 md4 d-flex class="ff-c">
          <div class="primary step radius-header">
            Step 1 เลือกจังหวัด
          </div>
          <div class="white pa-3 radius-bottom mb-3">
            <v-autocomplete
            v-model="province"
            :items="listProvince"
            item-text="province_name"
            item-value="province_id"
            label="กรุณาเลือกจังหวัด"
            @change="clear()"
            persistent-hint
            >
            <v-slide-x-reverse-transition
            slot="append-outer"
            mode="out-in"
            >
          </v-slide-x-reverse-transition>
        </v-autocomplete>
      </div>

      <div :class="[province == '' ? 'gray radius-header' : 'select radius-header']">
        <div class="">
          <span>Step 2 เลือกพื้นที่</span>
          <v-tooltip bottom>
            <v-icon
            slot="activator"
            color="blue"
            dark>information</v-icon>
            <span>กรมพัฒนาที่ดิน แบ่งดินในพื้นที่ลุ่มและพื้นที่ดอน เป็น 62 กลุ่มดิน <br>
              พื้นที่ลุ่ม มีกลุ่มดินที่ 1-25, 57 58 และ 59 <br>
              พื้นที่ดอน มีกลุ่มดิน 26-56 และ 60-62</span>
            </v-tooltip>
          </div>
        </div>
        <div class="white pa-2 radius-bottom mb-3 pt-0">
          <v-radio-group v-model="area" :disabled="province == '' ? true : false" class="font-checkbox">
            <v-radio
            v-for="a,index in listArea"
            :key="index"
            :label="`${a.area_name}`"
            :value="a.area_id"
            @change="showArea(a.area_id)"
            ></v-radio>
          </v-radio-group>
        </div>


        <v-slide-x-reverse-transition
        slot="append-outer"
        mode="in-out"
        v-if="area !== 1"
        >
        <div class="">
          <div :class="[area !== 2 ? 'gray radius-header' : 'select radius-header']">
            Step 3 เลือกชนิดดิน
          </div>
          <div class="white pa-2 radius-bottom pt-0">
            <v-radio-group v-model="soil" @change="showResult()" :disabled="area ==  2 ? false : true" >
              <v-radio
              v-for="i,index in listSoil"
              :key="index"
              :label="`${i.soil_name}`"
              :value="i.soil_id"
              class="font-checkbox"
              ></v-radio>
            </v-radio-group>
          </div>
        </div>
      </v-slide-x-reverse-transition>
    </v-flex>

    <v-flex xs12 md8 d-flex class="ff-c" >
      <div class="flex jc-c al-c white border-radius-content" v-if="listResult.length === 0">
        <h3 v-if="this.province === '' || this.area === '' || this.soil === ''" class="w-100 ta-c header-result">กรุณาเลือกจังหวัด พื้นที่ และชนิดดิน เพื่อแสดงผลลัพธ์ชนิดไม้ที่ควรปลูก</h3>
        <h3 v-if="listResult.length === 0 && (this.province !== '' && this.area !== '' && this.soil !== '')" class="w-100 ta-c header-result">ไม่พบข้อมูล</h3>
      </div>

      <div class="flex jc-c al-b white border-radius-content" v-if="listResult.length !== 0" >
        <v-layout row wrap pa-2 >
          <v-layout row wrap v-if="this.area === 1" justify-center align-center >
            <v-flex md12 xs12 class="ff-c jc-c ta-c" >
              <h3 class="jc-c mt-5" style="font-size: 28px;" id="first">พื้นที่ลุ่มไม่เหมาะสมกับการปลูกสวนป่า</h3>
              <h3 class="jc-c" style="font-size: 22px;">ปลูกได้บางชนิดบางพื้นที่</h3>
              <div class="flex jc-c">
                <div style="font-size: 18px; text-align:left;" class="pa-3">
                  <br>พื้นที่ลุ่มคือ พื้นที่ที่มีน้ำท่วมขัง ระบายน้ำได้ไม่ดี ได้แก่ ที่นา ที่ชายฝั่งทะเล
                  <br>สำหรับพื้นที่ลุ่มที่จะนำมาปลูกต้นไม้ ดังนี้
                  <ul>
                    <li>พื้นที่ดินเลนน้ำท่วมขัง ชนิดไม้ที่แนะนำ ได้แก่ ไม้โกงกาง</li>
                    <li>พื้นที่ดินทรายใกล้ชายฝั่งทะเล ชนิดไม้ที่แนะนำ ได้แก่ สนประดิพัทธ์ สนทะเล</li>
                    <li>พื้นที่บนคันนา ชนิดไม้ที่แนะนำ ได้แก่ ยูคาลิปตัส กระถินณรงค์ และกระถินเทพา</li>
                  </ul>

                </div>
              </div>
            </v-flex>
          </v-layout>
          <v-layout row wrap class="w-100" v-if="this.area !== 1 && listResult.length !== 0 && this.soil !== ''">
            <div class="w-100 mt-5" >
              <p class="header-result pl-4" >ชนิดไม้ที่ควรปลูกคือ :</p>
            </div>
            <v-flex xs12 md4 v-for="i in listResultGroup" class="ff-c">
              <div class="select ta-c radius-header" >
                {{i.category_name}}
              </div>
              <div class="radius-bottom" style="background-color: rgb(243, 255, 224)" >
                <div class="ta-c mb-1 mt-1" v-for="i in i.tree">
                  <!-- <a :class="[i.tree_link === '' ? 'no-link' : '']" :href="i.tree_link === '#' ? '' : i.tree_link" target="_blank" :disabled="i.tree_link === '' ? true : false">
                  <span class="header-result">{{i.tree_name}}</span></a> -->
                  <div class="flex">
                    <a :class="[i.tree_link === '' ? 'no-link' : '']" :href="i.tree_link === '#' ? '' : i.tree_link" target="_blank" :disabled="i.tree_link === '' ? true : false" style="flex:1">
                      <span class="header-result">{{i.tree_name}}</span>
                    </a>
                    <a :href="i.tree_e_book" v-if="i.tree_e_book !== '' " target="_blank" class="no-underline">
                      <v-tooltip bottom>
                        <v-icon
                        slot="activator"
                        color="orange"
                        dark
                        >import_contacts</v-icon>
                        <span>E-book</span>
                      </v-tooltip></a>
                    </div>
                  </div>
                </div>
              </v-flex>
            </v-layout>
          </v-layout>
          <div class="" id="soil_result">
          </div>
        </div>


      </v-flex>
      <v-flex xs12 sm12 md12 class="jc-fe">
        <v-card >
          <v-btn
          fixed
          dark
          fab
          bottom
          right
          color="pink"
          @click="modalSurvey()"
          >
          <v-icon>mail</v-icon>
        </v-btn>
      </v-card>
    </v-flex>
  </v-layout>
  <Modal ref="Modal"/>


</v-container>
</div>
</template>

<script>
import * as easings from 'vuetify/es5/util/easing-patterns'
var _ = require('lodash');
import Modal from '@/components/Modal'
import Loading from '@/components/Loading'
import {scroller} from 'vue-scrollto/src/scrollTo'
const firstScrollTo = scroller()
const secondScrollTo = scroller()
export default {
  components:{
    Modal,
    Loading
  },
  data: () => ({
    area: '',
    soil: '',
    listProvince: [],
    listArea: [],
    listSoil: [],
    listResult: [],
    listResultGroup: [],
    province: '',
    disabled: false,
    hasError: false,
    readonly: false,
    loading: false,
    text: '',
  }),
  created() {
    this.queryProvince()
    this.queryArea()
    this.querySoil()
  },
  methods:{
    queryProvince(){
      this.axios.post(process.env.VUE_APP_IPFLASK + '/queryProvince').then((response) => {
        // console.log(response.data);
        this.listProvince = response.data
      })
    },
    queryArea(){
      this.axios.post(process.env.VUE_APP_IPFLASK + '/queryArea').then((response) => {
        // console.log('oom');
        // console.log(response.data);
        this.listArea = response.data
      })
    },
    querySoil(){
      this.axios.post(process.env.VUE_APP_IPFLASK + '/querySoil').then((response) => {
        // console.log(response.data);
        this.listSoil = response.data
      })
    },
    showResult(areaID){
      this.loading = true
      // console.log(areaID);
      if (areaID === 1){
        this.area = 1
        this.$nextTick(() => {
          // var element = this.$refs.first;
          // var top = element.offsetTop;
          // window.scrollTo(0, top);
          firstScrollTo('#first')
        })
      } else {
        this.axios.post(process.env.VUE_APP_IPFLASK + '/showResult', {province: this.province, area: this.area, soil: this.soil}).then((response) => {
          this.listResult = response.data
          this.loading = false
          this.listResultGroup =  _.chain(this.listResult).groupBy("category_id").map(function(v, i) {
            return {
              category_id: i,
              category_name : _.get(_.find(v, 'category_name'), 'category_name'),
              tree: v
            }
          }).value();
          this.$nextTick(() => {
            // var element = this.$refs.soil_result;
            // var top = element.offsetTop;
            // window.scrollTo(0, top);
            secondScrollTo('#soil_result')
          })
        })
      }
      // console.log(this.area);
    },
    showArea(areaID){
      if (areaID === 1) {
        // console.log('พื้นที่ลุ่ม');
        this.listResult.length = 2
        this.showResult(1)
      } else {
        this.listResult = []
      }
      this.soil = ''
    },
    clear() {
      this.area = ''
      this.soil = ''
      this.listResult = []
    },
    modalSurvey(){
      this.$refs.Modal.openDialog()
    }
  }
}
</script>

<style scoped>
.header{
  background: var(--v-yellow-base);
  font-size: 18px;
  padding: 20px;
}

.white{
  background-color:white;
}

.gray{
  background-color: #dadada;
  font-size: 18px;
  color: #969696;
  padding: 15px;
}


.select{
  background-color: var(--v-primary-base);
  font-size: 18px;
  color: black;
  padding: 15px;
}

.step{
  font-size: 18px;
  color: black;
  padding: 15px;
}

.slide-fade-enter-active {
  transition: all .10s ease;
}

.header-result{
  font-size: 20px;
  color: black;
}

.radius-bottom{
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
}

.radius-header{
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}

.border-radius-content{
  border-radius: 16px;
}

ิ.test{
  background: pink !important;
}

.fade-enter-active {
  transition: opacity .1s
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

a.no-link{
  text-decoration: none !important;
  cursor: default;
  pointer-events: none;
}

.no-underline{
  text-decoration: none !important;
}

.font-checkbox{
  font-size: 18px;
}



</style>
