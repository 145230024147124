<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="500">
      <v-card>
        <v-card-title class="primary header">หากท่านต้องการรับข้อมูลข่าวสารเกี่ยวกับการปลูกสวนป่าในบริเวณดังกล่าว เช่น การแจกจ่ายกล้าไม้ หรือบริการอื่น ๆ ของกรมป่าไม้ โปรดกรอกข้อมูลติดต่อกลับด้านล่าง
        </v-card-title>
        <v-card-text>
          <form>
            <v-text-field
            v-model="name"
            label="ชื่อ-นามสกุล"
            required
            ></v-text-field>
            <v-textarea
            v-model="address"
            label="ที่อยู่"
            ></v-textarea>
            <v-text-field
            v-model="phone"
            label="โทรศัพท์"
            required
            ></v-text-field>
            <v-text-field
            v-model="email"
            label="E-mail"
            required
            ></v-text-field>

            <p class="header mt-2">พื้นที่ที่ต้องการปลูก</p>
            <v-text-field
            v-model="tambon"
            label="ตำบล"
            required
            ></v-text-field>
            <v-text-field
            v-model="district"
            label="อำเภอ"
            required
            ></v-text-field>
            <v-select
            v-model="province"
            :items="listProvince"
            item-text="province_name"
            item-value="province_name"
            label="จังหวัด"
            ></v-select>
          </form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black darken-1" flat @click="dialog = false">ยกเลิก</v-btn>
          <v-btn color="green darken-1" flat @click="submitSurvey()">ส่ง</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  data () {
    return {
      dialog: false,
      listProvince: [],
      name: '',
      address: '',
      phone: '',
      email: '',
      tambon : '',
      district: '',
      province: ''
    }
  },
  created() {
    this.queryProvince()
  },
  methods:{
    openDialog(){
      this.dialog = true
    },
    queryProvince(){
      this.axios.post(process.env.VUE_APP_IPFLASK + '/queryProvince').then((response) => {
        // console.log(response.data);
        this.listProvince = response.data
      })
    },
    submitSurvey(){
        this.axios.post(process.env.VUE_APP_IPFLASK + '/submitSurvey', {name: this.name, address: this.address, phone: this.phone, email: this.email, tambon: this.tambon, district: this.district, province: this.province}).then((response) => {
          if (response.data === 'Success'){
            console.log('ส่งข้อมูลสำเร็จ');
            this.dialog = false
            this.$swal('บันทึกข้อมูลเรียบร้อย','ขอบคุณสำหรับการสละเวลาในการทำแบบสอบถามนี้','success');
          }
        })

    },
  }
}
</script>

<style scoped>
.header{
  font-size: 16px;
}


</style>
