<template>
  <div class="">
    <v-toolbar class="bg-header hero hidden-sm-and-down jc-c" color="primary" height="86">
      <v-container style="padding:0 !important">
        <v-layout row wrap justify-center >
          <v-flex lg10 md12 sm12 xs12>
            <div class="center-flex">
              <div class="center-flex ml-2">
                <img src="../assets/rfd_logo_nobg.png" alt="" class="logo">
              </div>
              <div class="header-text">
                <v-toolbar-title class="white--text" style="font-size:24px;height:33px">การพิจารณาชนิดไม้เศรษฐกิจเพื่อการปลูกสวนป่า</v-toolbar-title>
                <v-toolbar-title class="white--text ma-0" style="font-size:18px">สำนักวิจัยและพัฒนาการป่าไม้ กรมป่าไม้</v-toolbar-title>
              </div>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-toolbar>
    <v-toolbar class="bg-header hero ff-c hidden-md-and-up" color="primary" height="115">
      <div class="center-flex w-100 mt-2 mb-2">
        <img src="../assets/rfd_logo_nobg.png" alt="" width="40">
      </div>
      <div class="t-a center-flex ff-c">
        <p class="white--text mb-0" style="font-size:16px; white-space: nowrap;">การพิจารณาชนิดไม้เศรษฐกิจเพื่อการปลูกสวนป่า</p>
        <p class="white--text" style="font-size:13px;">สำนักวิจัยและพัฒนาการป่าไม้ กรมป่าไม้</p>
      </div>
    </v-toolbar>


    <v-toolbar class="flex submenu-height hidden-sm-and-down ff-r" id="list-menu" height="50">
      <v-container style="display: contents;">
        <template v-for="item in menus">
          <v-list-tile
          :key="item.text"
          :to="item.path == '' ? '' : item.path"
          :exact="item.exact"
          active-class="primary"
          class="listmenu"
          @click="gotoLink(item.path)">
          <div >
            <span class="jc-c">{{ item.th }}</span>
          </div>
        </v-list-tile>
      </template>
    </v-container>
  </v-toolbar>

  <v-menu offset-y class="hidden-md-and-up w-100" id="header">
    <v-btn
    slot="activator"
    color="primary black--text"
    block
    >
    เมนู
  </v-btn>
  <v-list>
    <v-list-tile
    v-for="(item, index) in menus"
    :key="index"
    @click=""
    >
    <v-list-tile
    :key="item.text"
    :to="item.path == '' ? '' : item.path"
    :exact="item.exact"
    active-class="primary"
    class="listmenu"
    @click="gotoLink(item.path)">
    <div ><span class="jc-c">{{ item.th }}</span></div>
  </v-list-tile>
</v-list-tile>
</v-list>
</v-menu>

<v-content>
  <v-container pt-0>
    <v-layout justify-center align-center>
      <router-view></router-view>
    </v-layout>
  </v-container>
</v-content>

<!-- <v-footer class="pa-4 primary">
<div>&copy; {{ new Date().getFullYear() }}</div>
</v-footer> -->
</div>
</template>

<script>
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'home',
  data(){
    return {
      menus: [
        { th: 'การเลือกชนิดไม้ปลูกที่เหมาะสมกับพื้นที่', path: '/' },
        { th: 'การจัดการสวนป่าและรวมกลุ่มทางป่าไม้', path: '/tree_together'},
        { th: 'การปรับปรุงพันธุ์ไม้โตเร็ว', path: '/change_tree' },
        // { th: 'ชนิดไม้เศรษฐกิจที่สำคัญ',path: '' },
        // { th: 'คำถาม คำตอบ',path: '' }
      ]
    }
  },
  created(){

  },
  methods:{
    gotoLink(link) {
      if(link === '/tree_together'){
        this.$router.push('/')
        window.open('https://drive.google.com/file/d/1auO5-W9FebxgkJd1zHP6HuRT6rB1CBGL/view?fbclid=IwAR0fKvxU6EME0m9uqaXCSxBkwJWRGeVs_3yrFqcOgE7OkCSdod4OuJjaCMU');
      } else if (link === '/change_tree'){
        this.$router.push('/')
        window.open('http://forprod.forest.go.th/forprod/ebook/e-book13-3-57/u-pm.pdf');
      } else {
        // console.log(link);
        this.$router.push(link)
      }

    }
  }
}
</script>

<style scoped>
.listmenu{
  justify-content: center;
  display: flex;
  width: 100%;
  font-size: 18px;
  color: black;
  cursor: pointer;
  max-width:1150px;
}

.logo{
  max-height: 60px;
}

.header-text{
  padding: 15px 10px;
  flex: 8;
}

.bg-header{
  /* background-image: linear-gradient(to bottom, rgba(102, 150, 10, 0.85) 0%,rgba(102, 150, 10, 0.85)  100%), url('../assets/green.png'); */
  background-image: linear-gradient(to bottom, #8bc34ac7 0%,#66960afc  100%), url('../assets/green.png');
}
.submenu-height{
  background-color: white !important;
}



</style>
