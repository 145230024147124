<template lang="html">

</template>

<script>
export default {
  data () {
      return {
        indeterminate: true
      }
    },
}
</script>

<style lang="css">
</style>
